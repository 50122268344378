import React, { useState, useEffect } from "react";
import Header from "../../../Components/Global/Header";
import {
  signIn,
  signUp,
  confirmSignIn,
  getCurrentUser,
  fetchAuthSession,
} from "aws-amplify/auth";
import { useLocation } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Responsive from "../../../Hooks/Responsive";
import TransactionFormMobile from "../../../Components/SellerStepComponents/VerificationForm/Mobile";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  addTransaction,
  register_cognito_user,
  resendOtp,
  sendOtp,
  transactionUpdate,
  verifyOtp,
} from "../../../actions/CreateTransaction";

interface VerificationFormData {
  mobilenumber?: string;
  otp?: string;
  name?: string;
  address?: string;
}

const VerificationForm: React.FC = () => {
  const deviceType = Responsive();
  const navigate = useNavigate();
  const location = useLocation();
  const code = location.state?.code; // Safely access the code

  const [verificationFormData, setVerificationFormData] =
    useState<VerificationFormData>({
      mobilenumber: "",
      otp: "",
      name: "",
      address: "",
    });

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [otpDisable, setotpDisable] = useState<boolean>(false);

  const handleSendOtp = async () => {
    if (!verificationFormData?.mobilenumber) {
      return;
    }

    try {
      setIsLoading(true);

      await signUp({
        username: `+${verificationFormData?.mobilenumber}`,
        password: "12345678", // Temporary password
        options: {
          userAttributes: {
            phone_number: `+${verificationFormData?.mobilenumber}`,
            name: `+${verificationFormData?.mobilenumber}`,
          },
        },
      });

      handleLogin(); // Initiate login after sign-up
    } catch (error: any) {
      console.error("Error signing up:", error);

      if (error.message.toLowerCase().includes("already exists")) {
        handleLogin();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await signIn({
        username: `+${verificationFormData?.mobilenumber}`,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
          preferredChallenge: "SMS_OTP",
        },
      });
      setotpDisable(true);
    } catch (error: any) {
      console.error("Error logging in:", error);
      alert(error.message || "Error logging in.");
    } finally {
      //setLoading(false);
      setIsLoading(false);
      setisResendLoading(false);
    }
  };

  const [isLoadingVerification, setIsLoadingVerification] =
    useState<boolean>(false);

  const VerifyOtp = async () => {
    if (!verificationFormData?.mobilenumber || !verificationFormData?.otp) {
      return;
    }
    try {
      setIsLoadingVerification(true);

      const response = await confirmSignIn({
        challengeResponse: verificationFormData?.otp,
      });
      const { nextStep: confirmSignInNextStep } = response;
      if (confirmSignInNextStep.signInStep === "DONE") {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        const res = await register_cognito_user({
          token: accessToken,
          account_type: "seller",
        });
        setIsVerified(true);
        setVerificationFormData({
          ...verificationFormData,
          name: res?.data?.name || "",
          address: res?.data?.address || "",
        });
        localStorage.setItem("user", JSON.stringify(res?.data));
      }
    } catch (error: any) {
      console.error("Error:", error);
      alert(error.message || "An error occurred.");
    } finally {
      setIsLoadingVerification(false);
    }
  };

  useEffect(() => {
    if (verificationFormData?.otp && verificationFormData?.otp.length === 6) {
      VerifyOtp();
    }
  }, [verificationFormData.otp]);

  const [isResendLoading, setisResendLoading] = useState<boolean>(false);

  const handleResendOtp = async () => {
    if (!verificationFormData?.mobilenumber) {
      return;
    }
    try {
      setisResendLoading(true); // Start loading indicator

      const response = await resendOtp({
        contact_no: verificationFormData?.mobilenumber,
      });

      if (response?.otp) {
        window.alert(response?.otp);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setisResendLoading(false); // Stop loading indicator
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [transactionLoader, settransactionLoader] = useState(false);

  const update_Transaction = async () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    if (
      !user?.id ||
      !verificationFormData?.name ||
      (!verificationFormData?.address && !code)
    ) {
      return null;
    }
    // Create FormData and populate it

    const transactionData = {
      access_token: accessToken,
      seller_id: user?.id,
      seller_name: verificationFormData?.name,
      seller_address: verificationFormData?.address ?? "", // Default to an empty string
      code,
      is_viewed: true,
    };

    settransactionLoader(true);

    try {
      const response = await transactionUpdate(transactionData);

      if (response?.data) {
        setIsModalVisible(true);
        localStorage.setItem("user", JSON.stringify(response?.user));
      }
    } catch (error) {
      console.log(error);
    } finally {
      settransactionLoader(false);
    }
  };

  useEffect(() => {
    let savedUser = JSON.parse(localStorage.getItem("user") || "{}");
    // if(savedUser)
    if (Object.keys(savedUser).length > 0) {
      // setVerificationFormData()
      setVerificationFormData({
        ...verificationFormData,
        name: savedUser?.name,
        address: savedUser?.address,
      });
    }
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />

      <Layout
        style={{
          flex: 1,
          overflowX: "hidden",
          backgroundColor: "#F5F5F5",
        }}
      >
        {/* Scrollable Content */}
        <Content style={{ overflowY: "auto", padding: "15px", flexGrow: 1 }}>
          {deviceType === "desktop" ? (
            <TransactionFormMobile
              onbackClick={() => {
                navigate(-1);
              }}
              verificationformdata={verificationFormData}
              setverificationformdata={setVerificationFormData}
              isVerified={isVerified}
              sendOtp={() => {
                handleSendOtp();
              }}
              otpDisable={otpDisable}
              setOtpDisable={setotpDisable}
              isLoading={isLoading}
              verifyOtp={() => {
                VerifyOtp();
              }}
              isLoadingVerification={isLoadingVerification}
              resendOtp={() => {
                setisResendLoading(true);
                handleLogin();
              }}
              isResendLoading={isResendLoading}
              create_transaction={update_Transaction}
              transactionLoading={transactionLoader}
              isModalVisible={isModalVisible}
              handleOk={() => {
                setIsModalVisible(false);
              }}
            />
          ) : (
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              {/* <TransactionFormMobile
                onbackClick={() => {
                  navigate(-1);
                }}
                verificationformdata={verificationFormData}
                setverificationformdata={setVerificationFormData}
              /> */}
              <TransactionFormMobile
                onbackClick={() => {
                  navigate(-1);
                }}
                verificationformdata={verificationFormData}
                setverificationformdata={setVerificationFormData}
                isVerified={isVerified}
                sendOtp={() => {
                  handleSendOtp();
                }}
                otpDisable={otpDisable}
                setOtpDisable={setotpDisable}
                isLoading={isLoading}
                verifyOtp={() => {
                  VerifyOtp();
                }}
                isLoadingVerification={isLoadingVerification}
                resendOtp={() => {
                  setisResendLoading(true);
                  handleLogin();
                }}
                isResendLoading={isResendLoading}
                create_transaction={update_Transaction}
                transactionLoading={transactionLoader}
                isModalVisible={isModalVisible}
                handleOk={() => {
                  setIsModalVisible(false);
                }}
              />
            </motion.div>
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default VerificationForm;
