import { Button } from "antd";
import React from "react";

interface IconButtonProps {
    icon: React.ReactNode;
    label: string;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

const IconButtonDesktop: React.FC<IconButtonProps> = ({
    icon,
    label,
    onClick,
    className,
    style
}) => {
    return (
        <Button
            className={className}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: "#9FE870",
                borderRadius: "100px",
                height: "36px",
                width: "100%",
                border: "1px solid #9FE870",
                fontFamily: "Inter",
                fontWeight: 700,
                transition: "transform 0.1s ease-in-out", // Add transition for animation
                ...style,
            }}
            onClick={onClick}
            onMouseDown={(e) => e.currentTarget.style.transform = "scale(0.95)"} // Scale down on press
            onMouseUp={(e) => e.currentTarget.style.transform = "scale(1)"} // Reset scale on release
            onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"} // Reset scale if mouse leaves
        >
            <div style={{ fontSize: "24px", marginBottom: "7px", marginRight: '6px', color: "#163300" }}>{icon}</div>
            <span style={{
                fontSize: "14px", color: "#163300", userSelect: "none", fontFamily: "Inter",
                fontWeight: 700,
            }}>{label}</span>
        </Button>
    );
};

export default IconButtonDesktop;
