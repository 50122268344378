import React from "react";
import { ShoppingCartOutlined, ShopOutlined, LinkOutlined } from "@ant-design/icons";
import SelectableButton from "../../Global/SelectableButton/Desktop";
import { Card, Space, Switch } from "antd-mobile";
import color from "../../../Utils/color";
import CustomInput from "../../Global/CustomInput/Desktop";
import CustomButton from "../../Global/CustomButton/Desktop";
import { Grid } from "antd";
import IconButtonDesktop from "../../Global/IconButton/Desktop";
import toast from "antd-mobile/es/components/toast";
import ShareOnSocial from "react-share-on-social";
import NotificationBoxMobile from "../../Global/NotificationBox/Mobile";
import { WarningOutlined } from "@ant-design/icons";

interface TransactionLinkDesktopProps {
  selectedRole?: string;
}

const TransactionLinkDesktop: React.FC<TransactionLinkDesktopProps> = ({ selectedRole }) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  // Add transaction link constant
  const transactionLink = "https://trustpay.lk/transaction/9hfdu0jik3";

  // Add share handler
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'TrustPay Transaction',
          text: 'Here is your transaction link',
          url: transactionLink,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Web Share API not supported in your browser');
    }
  };

  // Add copy handler
  const handleCopy = () => {
    navigator.clipboard.writeText(transactionLink)
      .then(() => {
        toast.show('Link copied to clipboard!');
      })
      .catch((error) => {
        console.error('Error copying text:', error);
      });
  };

  return (
    <div>
      <h2 style={{ textAlign: "center", marginBottom: "40px", fontWeight: "700", fontFamily: "Inter", }}>Transaction Created Successfully</h2>
      <p style={{ fontSize: '15px', marginBottom: "20px", fontWeight: "500", fontFamily: "Inter", }}>Now you can share this link with the seller.</p>

      <CustomInput
        placeholder=""
        value={transactionLink}
        disabled={true}
        onChange={() => { }}
        style={{
          fontSize: '12px',
          color: "#000000",
          padding: "0 20px",
          userSelect: "none",
          fontWeight: "400",
          fontFamily: "Inter",
        }}
      />


      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "5%",
        }}
      >
        <ShareOnSocial
          shareTo={['facebook', 'whatsapp', 'twitter', 'email']}
          textToShare="Checkout this post"
          link={transactionLink}
          linkFavicon="https://api.keyp.co.za/storage/favicon.ico"
          noReferer
          sx={{ cursor: 'pointer' }} // This makes the entire ShareOnSocial component clickable with a hand cursor
        >
          <IconButtonDesktop
            className="custom-button"
            icon={<LinkOutlined style={{ color: "#163300", fontSize: "16px" }} />}
            label="Share Link"
            // onClick={handleShare}
            style={{
              width: screens.xs ? "150px" : screens.md ? "100%" : "100%",
            }}
          />
        </ShareOnSocial>
        <CustomButton
          type="outline"
          label="Copy Link"
          onClick={handleCopy}
          style={{
            width: screens.xs ? "150px" : screens.md ? "100%" : "100%",
          }}
        />

      </Space>

      {selectedRole === "seller" && (
        <>
          <NotificationBoxMobile
            icon={<WarningOutlined style={{ fontSize: "12px", marginRight: '7px' }} />}
            title="Action Required"
            backgroundColor="#FEE6D3"
            borderColor="#FF9500"
            style={{ width: "110px" }}
            styletitle={{ lineHeight: '14px', fontSize: '10px' }}
            padding="2px 5px"
          />

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{ fontSize: '11px', fontWeight: '500', fontFamily: 'Inter', color: '#000000', marginRight: '10px' }}>Complete your bank details and verify your ID to receive payment as soon as the transaction completes.</p>
            <Switch
              style={{
                '--checked-color': '#34C759',
                '--border-width': '1px',
                '--height': '20px',
                '--width': '30px',
              } as any}
            />
          </div>
        </>
      )}

    </div>
  );
};

export default TransactionLinkDesktop;
