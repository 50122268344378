import React, { useState } from "react";
import NotificationBoxMobile from "../NotificationBox/Mobile";
import {
  InformationCircleOutline,
  LockOutline,
  CameraOutline,
} from "antd-mobile-icons";
import {
  WarningOutlined,
  FieldTimeOutlined,
  CheckOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import ProductCardMobile from "../ProductCard/Mobile";

import { Card, Space, Switch } from "antd-mobile";
import CustomInputMobile from "../CustomInput/Mobile";
import { motion } from "framer-motion";
import CustomButtonMobile from "../CustomButton/Mobile";
import CustomDropdownMobile from "../CustomDropDown/Mobile";
import CameraCustomMobile from "../CameraCustom/Mobile";

interface ProceedPaymentProps {
  currentStep: number;
  completedSteps: number[];
  steps: any;
  productData: any;
  formdata: any;
  setFormData: any;
  handleOpenFrontCamera: any;
  handleOpenBackCamera: any;
  handleSubmit: any;
  data: any;
}

const ProceedPaymentMobile: React.FC<ProceedPaymentProps> = ({
  currentStep,
  completedSteps,
  steps,
  productData,
  formdata,
  setFormData,
  handleOpenFrontCamera,
  handleOpenBackCamera,
  handleSubmit,
  data,
}) => {
  console.log("🚀 ~ data:", data.status);

  return (
    <div>
      <h2
        style={{
          fontSize: "17px",
          fontWeight: "700",
          fontFamily: "Inter",
          color: "#000000",
          textAlign: "center",
          margin: "0 10px",
        }}
      >
        Seller has joined the transaction
      </h2>

      <NotificationBoxMobile
        icon={
          <WarningOutlined
            style={{ fontSize: "15px", marginRight: "7px", color: "#FF3B30" }}
          />
        }
        title="Please make payment to the TrustPay hold. "
        backgroundColor="#FBDDDD"
        borderColor="#FF3B30"
      />

      <div style={styles.container}>
        {steps.map((step: any, index: any) => {
          const isCompleted = completedSteps.includes(step.id);
          const isActive = step.id === currentStep;

          return (
            <div key={step.id} style={styles.stepContainer2}>
              <div style={styles.stepInnerContainer}>
                <div style={styles.stepContentContainer}>
                  <div
                    style={{
                      ...styles.circle,
                      ...(isCompleted ? styles.completedCircle : {}),
                      ...(isActive ? styles.activeCircle : {}),
                    }}
                  >
                    {isCompleted && (
                      <CheckOutlined
                        style={{ fontSize: "9px", borderRadius: "100%" }}
                      />
                    )}
                    <span
                      style={{
                        ...styles.checkMark,
                        backgroundColor: isActive
                          ? "#FF9800"
                          : isCompleted
                          ? "#4CAF50"
                          : "#AEAEB2",
                      }}
                    ></span>
                  </div>
                  {index < steps.length - 1 && <div style={styles.line} />}
                </div>
                <div style={styles.labelContainer}>
                  <span
                    style={{
                      ...styles.label,
                      ...(isActive ? styles.activeLabel : {}),
                      ...(isCompleted ? styles.completedLabel : {}),
                    }}
                  >
                    {currentStep === step.id && currentStep === 1
                      ? "Waiting for your payment"
                      : currentStep === step.id && currentStep === 2
                      ? "Waiting for your confirmation"
                      : step.label}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <ProductCardMobile
        title={productData.title}
        price={productData.price}
        description={productData.description}
        images={productData.images}
      />

      <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontFamily: "Inter",
            color: "#000000",
            width: "95px",
            margin: 0,
          }}
        >
          Price
        </p>
        <p
          style={{
            fontSize: "12px",
            color: "#000000",
            flex: 1,
            margin: 0,
            fontWeight: "400",
            fontFamily: "Inter",
            textAlign: "right",
          }}
        >
          LKR. 170,000.00
        </p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontFamily: "Inter",
            color: "#000000",
            width: "95px",
            margin: 0,
          }}
        >
          Buyer Protection
        </p>
        <p
          style={{
            fontSize: "12px",
            color: "#000000",
            flex: 1,
            margin: 0,
            fontWeight: "400",
            fontFamily: "Inter",
            textAlign: "right",
          }}
        >
          LKR.3,400.00
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
          marginTop: "10%",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "Inter",
            color: "#000000",
            margin: "0 10px 0 0",
          }}
        >
          Total Price :
        </p>
        <p
          style={{
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "Inter",
            color: "#000000",
            flex: 1,
            margin: 0,
            textAlign: "right",
          }}
        >
          LKR.173,400.00
        </p>
      </div>

      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: "10%",
        }}
      >
        <CustomButtonMobile
          className="custom-button"
          label="Proceed to Payment"
          onClick={() => {
            handleSubmit();
          }}
          // disabled={!formdata.seller_invite_terms}
          style={{
            width: "100%",
            fontSize: "12px",
          }}
        />
      </Space>

      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
          marginTop: "30px",
        }}
      >
        <a
          href="#"
          style={{
            color: "#000000",
            textDecorationLine: "underline",
            fontWeight: "400",
            fontFamily: "Inter",
          }}
        >
          Need Help? Contact Support
        </a>
      </span>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "20px 0",
    width: "100%",
    marginTop: "20px",
  },
  stepContainer2: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100px",
  },
  stepInnerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    // gap: "12px",
  },
  stepContentContainer: {
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    position: "relative" as const,
  },
  circle: {
    width: "17px",
    height: "17px",
    borderRadius: "50%",
    border: "2px solid #AEAEB2",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  activeCircle: {
    border: "1.5px solid #FF9800",
  },
  completedCircle: {
    border: "1.5px solid #000000",
    backgroundColor: "#4CAF50",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "3px",
  },
  checkMark: {
    // color: "#fff",
    // fontSize: "14px",
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    backgroundColor: "gray",
    // marginLeft: "0.7px",
  },
  labelContainer: {
    marginTop: "4px",
    textAlign: "center" as const,
  },
  label: {
    fontSize: "10px",
    color: "#8E8E93",
    maxWidth: "50px",
    display: "block",
    fontWeight: "400",
    fontFamily: "Inter",
  },
  activeLabel: {
    color: "#FF9800",
    fontWeight: "500",
  },
  completedLabel: {
    color: "#000000",
    fontWeight: "500",
  },
  line: {
    width: "31vw",
    height: "1px",
    margin: "0 4px 0px 4px",
    backgroundColor: "#AEAEB2",
    position: "absolute" as const,
    left: "100%",
    top: "50%",
    transform: "translateY(-50%)",
  },
};

export default ProceedPaymentMobile;
