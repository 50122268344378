import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Space,
  Layout,
  Card,
  Grid,
  Checkbox,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import CustomButton from "../../Global/CustomButton/Desktop";
import CustomInput from "../../Global/CustomInput/Desktop";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { motion } from "framer-motion";
import NotificationBoxMobile from "../../Global/NotificationBox/Mobile";
import { WarningOutlined } from "@ant-design/icons";
import { Switch } from "antd-mobile";

interface VerificationFormData {
  mobilenumber?: string;
  otp?: string;
  name?: string;
  address?: string;
  email?: string;
  termsAccepted?: boolean;
  selectedRole?: string;
  actionRequired?: boolean;
}

interface ShareLinkProps {
  onbackClick: () => void;
  verificationformdata: VerificationFormData;
  setverificationformdata: React.Dispatch<
    React.SetStateAction<VerificationFormData>
  >;
  selectedRole?: string;
}

const Verification: React.FC<ShareLinkProps> = ({
  onbackClick,
  verificationformdata,
  setverificationformdata,
  selectedRole
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { Title, Text } = Typography;

  const navigate = useNavigate();
  const [otpshow, setotpshow] = useState({
    otp: false,
    detail: false,
  });

  const onFinish = (values: any) => {
    console.log("Form Values:", values);
  };

  const otpInputStyle: React.CSSProperties = {
    width: "50px",
    height: "50px",
    textAlign: "center",
    fontSize: "16px",
    borderRadius: "8px",
    boxSizing: "border-box",
    padding: "6px",
    borderWidth: "1.5px",
    borderStyle: "solid",
    borderColor: "#ccc",
    fontWeight: "400",
    fontFamily: "Inter",
  };

  const handleTermsChange = (e: CheckboxChangeEvent) => {
    setverificationformdata({
      ...verificationformdata,
      termsAccepted: e.target.checked,
    });
  };

  return (
    <Layout
      style={{
        margin: "0 auto",
        flex: 1,
      }}
    >
      <Content>
        <Card
          style={{
            flex: 1,
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            paddingBottom: 5,
          }}
        >
          <div
            style={{
              marginBottom: 5,
              flex: 1,
            }}
          >
            <Title
              level={4}
              style={{
                textAlign: "center",
                marginTop: 0,
                fontWeight: "700",
                fontFamily: "Inter",
              }}
            >
              Verification
            </Title>
            <Form layout="vertical" onFinish={onFinish}>
              <h4 style={{ marginBottom: "3px", fontWeight: "600", fontFamily: "Inter", }}>Mobile Number</h4>
              <Row
                align="middle"
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  maxWidth: "100%",
                  marginTop: "8px",
                  width: "100%",
                }}
              >
                <Col flex="1">
                  <CustomInput
                    placeholder="Enter your mobile number"
                    type="number"
                    // inputMode="email"
                    value={verificationformdata.mobilenumber}
                    onChange={(e) => {
                      setverificationformdata({
                        ...verificationformdata,
                        mobilenumber: e.target.value,
                      });
                    }}
                    style={{
                      border: "none",
                      borderBottomRightRadius: "0px",
                      borderTopRightRadius: "0px",
                    }}
                  />
                </Col>
                <Col>
                  <CustomButton
                    type="default"
                    label="Send OTP"
                    onClick={() => {
                      setotpshow({
                        ...otpshow,
                        otp: true,
                      });
                    }}
                    style={{
                      marginTop: 0,
                      height: 47,
                      border: "none",
                      borderRadius: "5px",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                    }}
                  />
                </Col>
              </Row>

              {otpshow.otp && verificationformdata && (
                <motion.div
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <div>
                    <h4 style={{ marginBottom: "3px" }}>Enter OTP</h4>
                    <OTPInput
                      numInputs={6}
                      value={verificationformdata?.otp || ""}
                      onChange={(e) => {
                        setverificationformdata({
                          ...verificationformdata,
                          otp: e,
                        });
                      }}
                      renderSeparator={
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      }
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={otpInputStyle}
                          type="tel"
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                    <Text
                      style={{
                        display: "block",
                        marginTop: "8px",
                        fontSize: "12px",
                        fontWeight: "400",
                        fontFamily: "Inter",
                      }}
                    >
                      Didn’t receive the code?{" "}
                      <a
                        style={{
                          color: "#000",
                          textDecorationLine: "underline",
                          fontWeight: "600",
                          fontFamily: "Inter",
                        }}
                      >
                        Resend OTP
                      </a>
                    </Text>
                  </div>
                </motion.div>
              )}
              {verificationformdata?.otp && (
                <motion.div
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <div>
                    <h4 style={{ marginBottom: "3px", fontWeight: "600", fontFamily: "Inter", }}>Name</h4>
                    <CustomInput
                      placeholder="Your Full Name"
                      value={verificationformdata?.name || ""}
                      onChange={(e) => {
                        setverificationformdata({
                          ...verificationformdata,
                          name: e.target.value,
                        });
                      }}
                    />
                    <h4 style={{ marginBottom: "3px", fontWeight: "600", fontFamily: "Inter", }}>Delivery Address</h4>
                    <Input.TextArea
                      placeholder="Your Delivery Address."
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      value={verificationformdata?.address || ""}
                      onChange={(e) => {
                        setverificationformdata({
                          ...verificationformdata,
                          address: e.target.value,
                        });
                      }}
                      style={{
                        borderRadius: "8px",
                      }}
                    />
                    <h4 style={{ marginBottom: "3px", fontWeight: "600", fontFamily: "Inter", }}>Email</h4>
                    <CustomInput
                      placeholder="Your email"
                      value={verificationformdata?.email || ""}
                      onChange={(e) => {
                        setverificationformdata({
                          ...verificationformdata,
                          email: e.target.value,
                        });
                      }}
                    />

                    {selectedRole === "seller" && (
                      <>
                        <NotificationBoxMobile
                          icon={<WarningOutlined style={{ fontSize: "12px", marginRight: '7px' }} />}
                          title="Action Required"
                          backgroundColor="#FEE6D3"
                          borderColor="#FF9500"
                          style={{ width: "110px" }}
                          styletitle={{ lineHeight: '14px', fontSize: '10px' }}
                          padding="2px 5px"
                        />

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <p style={{ fontSize: '11px', fontWeight: '500', fontFamily: 'Inter', color: '#000000', marginRight: '10px' }}>Complete your bank details and verify your ID to receive payment as soon as the transaction completes.</p>
                          <Switch
                            style={{
                              '--checked-color': '#34C759',
                              '--border-width': '1px',
                              '--height': '20px',
                              '--width': '30px',
                            } as any}
                          />
                        </div>
                      </>
                    )}

                    {selectedRole === "buyer" && (
                      <div style={{ marginTop: "20px", marginBottom: "20px", display: "flex", alignItems: "flex-start" }}>
                        <Checkbox
                          checked={verificationformdata.termsAccepted}
                          onChange={handleTermsChange}
                        >
                          <span style={{ fontSize: "14px", marginLeft: "5px", fontWeight: "400", fontFamily: "Inter", }}>
                            I agree with the{" "}
                            <a href="/terms" style={{ color: "#1890ff", textDecorationLine: "underline", fontWeight: "600", fontFamily: "Inter", }}>
                              Terms and Conditions
                            </a>{" "}
                            and the{" "}
                            <a href="/privacy" style={{ color: "#1890ff", textDecorationLine: "underline", fontWeight: "600", fontFamily: "Inter", }}>
                              Privacy Policy
                            </a>
                          </span>
                        </Checkbox>
                      </div>
                    )}
                  </div>
                </motion.div>
              )}
            </Form>
          </div>

          <Space
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              type="outline"
              label="Previous"
              onClick={onbackClick}
              style={{
                width: screens.xs ? "150px" : screens.md ? "45vw" : "45vw",
              }}
            />
            <CustomButton
              disabled={
                !verificationformdata?.name ||
                !verificationformdata?.email ||
                !verificationformdata?.address ||
                (selectedRole === "buyer" && !verificationformdata?.termsAccepted)
                // (selectedRole === "seller" && !verificationformdata.actionRequired)
              }
              label="Create Transaction"
              onClick={() => {
                localStorage.setItem(
                  "verification",
                  JSON.stringify(verificationformdata)
                );

                navigate("/create_transaction/transaction_link", { state: { selectedRole: selectedRole } });
              }}
              style={{
                width: screens.xs ? "150px" : screens.md ? "45vw" : "45vw",
              }}
            />
          </Space>

        </Card>
      </Content>
    </Layout>
  );
};

export default Verification;
