import React, { useState, useEffect } from "react";
import NotificationBoxMobile from "../NotificationBox/Mobile";
import {
  InformationCircleOutline,
  LockOutline,
  CameraOutline,
} from "antd-mobile-icons";
import { WarningOutlined } from "@ant-design/icons";
import { list } from "aws-amplify/storage/s3";
import ProductCardMobile from "../ProductCard/Mobile";

import { Card, Switch } from "antd-mobile";
import CustomInputMobile from "../CustomInput/Mobile";
import { motion } from "framer-motion";
import CustomButtonMobile from "../CustomButton/Mobile";
import CustomDropdownMobile from "../CustomDropDown/Mobile";
import CameraCustomMobile from "../CameraCustom/Mobile";

interface StepIndicatorProps {
  currentStep: number;
  completedSteps: number[];
  steps: any;
  productData: any;
}

const BuyerWaiting: React.FC<StepIndicatorProps> = ({
  currentStep,
  completedSteps,
  steps,
  productData,
}) => {
  interface FileItem {
    path: string;
  }
  // Define the state type for files
  const [files, setFiles] = useState<string[]>([]);

  const getAllfilesInFolder = async (path: string) => {
    try {
      const result = await list({
        path: path, //`product_images/${requestValue}/`,
      });

      console.log(result);
      // Ensure the result has an 'items' array and it's an array of FileItem
      if (result.items && Array.isArray(result.items)) {
        const newFiles: string[] = result.items.map((item: FileItem) => {
          const previewUrl = `https://trustpay-temp-bucket.s3.us-east-1.amazonaws.com/${item.path}`;
          return previewUrl;
        });

        // Update the state with the newly generated files
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    if (productData) {
      getAllfilesInFolder(productData?.path);
    }
  }, [productData]);

  return (
    <div>
      <h2
        style={{
          fontSize: "17px",
          fontWeight: "700",
          fontFamily: "Inter",
          color: "#000000",
          textAlign: "center",
          margin: "0 10px",
        }}
      >
        {productData?.seller_id == null
          ? "Waiting for seller to join"
          : "Seller has joined the transaction"}
      </h2>
      {productData?.seller_id == null ? (
        <NotificationBoxMobile
          icon={
            <InformationCircleOutline
              style={{ fontSize: "15px", marginRight: "7px" }}
            />
          }
          title={
            "Please share the unique link provided below with the seller to invite them to join this transaction."
          }
        />
      ) : (
        <NotificationBoxMobile
          icon={
            <WarningOutlined
              style={{ fontSize: "15px", marginRight: "7px", color: "#FF3B30" }}
            />
          }
          title="Please make payment to the TrustPay hold. "
          backgroundColor="#FBDDDD"
          borderColor="#FF3B30"
        />
      )}

      {/* /Please make payment to the TrustPay hold. */}

      <div style={styles.container}>
        {steps.map((step: any, index: any) => {
          const isCompleted = completedSteps.includes(step.id);
          const isActive = step.id === currentStep;

          return (
            <div key={step.id} style={styles.stepContainer2}>
              <div style={styles.stepInnerContainer}>
                <div style={styles.stepContentContainer}>
                  <div
                    style={{
                      ...styles.circle,
                      ...(isCompleted ? styles.completedCircle : {}),
                      ...(isActive ? styles.activeCircle : {}),
                    }}
                  >
                    <span
                      style={{
                        ...styles.checkMark,
                        backgroundColor: isActive
                          ? "#FF9800"
                          : isCompleted
                          ? "#4CAF50"
                          : "#AEAEB2",
                      }}
                    ></span>
                  </div>
                  {index < steps.length - 1 && <div style={styles.line} />}
                </div>
                <div style={styles.labelContainer}>
                  <span
                    style={{
                      ...styles.label,
                      ...(isActive ? styles.activeLabel : {}),
                      ...(isCompleted ? styles.completedLabel : {}),
                    }}
                  >
                    {step.label}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <ProductCardMobile
        title={productData.product_title}
        price={productData.price}
        description={productData.description}
        images={files}
      />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "20px 0",
    width: "100%",
    marginTop: "20px",
  },
  stepContainer2: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100px",
  },
  stepInnerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    // gap: "12px",
  },
  stepContentContainer: {
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    position: "relative" as const,
  },
  circle: {
    width: "17px",
    height: "17px",
    borderRadius: "50%",
    border: "2px solid #AEAEB2",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  activeCircle: {
    border: "2px solid #FF9800",
  },
  completedCircle: {
    border: "2px solid #4CAF50",
    backgroundColor: "#4CAF50",
  },
  checkMark: {
    // color: "#fff",
    // fontSize: "14px",
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    backgroundColor: "gray",
    // marginLeft: "0.7px",
  },
  labelContainer: {
    marginTop: "4px",
    textAlign: "center" as const,
  },
  label: {
    fontSize: "10px",
    color: "#8E8E93",
    maxWidth: "50px",
    display: "block",
    fontWeight: "400",
    fontFamily: "Inter",
  },
  activeLabel: {
    color: "#FF9800",
    fontWeight: "500",
  },
  completedLabel: {
    color: "#4CAF50",
    fontWeight: "500",
  },
  line: {
    width: "31vw",
    height: "1px",
    margin: "0 4px 0px 4px",
    backgroundColor: "#AEAEB2",
    position: "absolute" as const,
    left: "100%",
    top: "50%",
    transform: "translateY(-50%)",
  },
};

export default BuyerWaiting;
