import axios from "axios";
import { api } from "../../constants/api";

type ApiResponse<T = any> = {
  success?: boolean;
  message?: string;
  data?: T;
  otp?: number;
  error?: string | { status: number; [key: string]: any }; // Can be string or object
  user?: any;
};

interface SendOtpParams {
  contact_no: string;
}

export const sendOtp = async ({
  contact_no,
}: SendOtpParams): Promise<ApiResponse> => {
  try {
    const formData = new FormData();
    formData.append("contact_no", contact_no);

    const res = await axios.post(api.sendOtp, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error: any) {
    console.error("Error sending OTP:", error);
    throw error;
  }
};

interface verifyOtpParams {
  contact_no: string;
  otp: string;
}

export const verifyOtp = async ({
  contact_no,
  otp,
}: verifyOtpParams): Promise<ApiResponse> => {
  try {
    const formData = new FormData();
    formData.append("contact_no", contact_no);
    formData.append("otp", otp);

    const res = await axios.post(api.verifyOtp, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error: any) {
    console.error("Error sending OTP:", error);
    throw error;
  }
};

interface resendOtpParams {
  contact_no: string;
}

export const resendOtp = async ({
  contact_no,
}: resendOtpParams): Promise<ApiResponse> => {
  try {
    const formData = new FormData();
    formData.append("contact_no", contact_no);

    const res = await axios.post(api.resendOtp, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error: any) {
    console.error("Error sending OTP:", error);
    throw error;
  }
};

interface createTransactionParams {
  access_token: any;
  type: string;
  product_url: string;
  product_title: string;
  description: string;
  price: string;
  name: string;
  email: string;
  delivery_address: string;
  payment_type: string;
  code: string;
  // product_image_ids: string;
}

export const addTransaction = async ({
  access_token,
  // user_id,
  type,
  product_url,
  product_title,
  description,
  price,
  name,
  email,
  delivery_address,
  payment_type,
  code,
}: // product_image_ids,
createTransactionParams): Promise<ApiResponse> => {
  try {
    const formData = new FormData();
    // formData.append("user_id", user_id);
    formData.append("type", type);
    product_url && formData.append("product_url", product_url);
    product_title && formData.append("product_title", product_title);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("code", code);

    formData.append("name", name);
    formData.append("email", email);
    formData.append("delivery_address", delivery_address);
    formData.append("payment_type", payment_type);
    // product_image_ids &&
    //   formData.append("product_image_ids", product_image_ids);

    const res = await axios.post(api.create_transaction, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${access_token}`,
      },
    });

    return res.data;
  } catch (error: any) {
    console.error("Error posting transaction:", error);
    throw error;
  }
};
interface GetTransactionByCodeParams {
  code: string;
}

// Updated function: getTransactionByCode
export const get_Transaction_By_Code = async ({
  code,
}: GetTransactionByCodeParams): Promise<ApiResponse> => {
  try {
    const res = await axios.get(api.get_tranaction_by_code, {
      params: { code }, // Add query parameter
      headers: {
        "Content-Type": "application/json", // Optional header
      },
    });

    return res.data;
  } catch (error: any) {
    console.error("Error fetching transaction by code:", error);
    throw error;
  }
};

interface transactionUpdateParams {
  access_token: any;
  seller_id: string;
  seller_name: string;
  seller_address: string;
  code: string;
  is_viewed: any;
  // product_image_ids: string;
}

export const transactionUpdate = async ({
  access_token,
  seller_id,
  seller_name,
  seller_address,
  code,
  is_viewed,
}: // product_image_ids,
transactionUpdateParams): Promise<ApiResponse> => {
  try {
    const formData = new FormData();
    formData.append("seller_id", seller_id);
    formData.append("seller_name", seller_name);
    formData.append("seller_address", seller_address);
    formData.append("code", code);
    formData.append("is_viewed", is_viewed);

    const res = await axios.post(api.update_transaction, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${access_token}`,
      },
    });

    return res.data;
  } catch (error: any) {
    console.error("Error posting transaction:", error);
    throw error;
  }
};

interface register_cognito_userParams {
  token: any;
  account_type: string;
}

export const register_cognito_user = async ({
  token,
  account_type,
}: // product_image_ids,
register_cognito_userParams): Promise<ApiResponse> => {
  try {
    const formData = new FormData();

    formData.append("account_type", account_type);

    const res = await axios.post(api.register_user, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (error: any) {
    console.error("Error posting transaction:", error);
    throw error;
  }
};
