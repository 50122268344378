import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";

interface CameraCustomMobileProps {
    setFormData: (data: any) => void;
    formData: any;
    handleCloseCamera: () => void;
    openFrontCamera: boolean;
    openBackCamera: boolean;
    setOpenBackCamera: (open: boolean) => void;
    setOpenFrontCamera: (open: boolean) => void;
}

const CameraCustomMobile: React.FC<CameraCustomMobileProps> = ({
    setFormData,
    formData,
    handleCloseCamera,
    openFrontCamera,
    openBackCamera,
    setOpenBackCamera,
    setOpenFrontCamera,
}) => {
    console.log("🚀 ~ openBackCamera:", openBackCamera)
    console.log("🚀 ~ openFrontCamera:", openFrontCamera)
    const [hasPermission, setHasPermission] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const webcamRef = useRef<Webcam>(null);

    useEffect(() => {
        const requestPermission = async () => {
            try {
                // First check if getUserMedia is supported
                if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                    // Check if it's an older browser that uses prefixed versions
                    const getUserMedia = (navigator as any).getUserMedia ||
                        (navigator as any).webkitGetUserMedia ||
                        (navigator as any).mozGetUserMedia ||
                        (navigator as any).msGetUserMedia;

                    if (!getUserMedia) {
                        console.error("getUserMedia API is not supported on this browser");
                        setError(`
                            Your browser doesn't support camera access.
                            Please try:
                            1. Using Chrome (version 47+)
                            2. Using Safari (version 11+)
                            3. Updating your browser to the latest version
                        `);
                        return;
                    }
                }

                const permissions = await navigator.permissions.query({ name: 'camera' as PermissionName });
                console.log("Current permission state:", permissions.state);

                try {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            facingMode: "environment",
                            width: { ideal: 1280 },
                            height: { ideal: 720 }
                        }
                    });

                    if (stream) {
                        setHasPermission(true);
                        stream.getTracks().forEach(track => track.stop());
                    }
                } catch (err) {
                    console.error("Detailed getUserMedia error:", {
                        error: err,
                        browserInfo: {
                            userAgent: navigator.userAgent,
                            vendor: navigator.vendor,
                            platform: navigator.platform,
                            mediaDevices: !!navigator.mediaDevices,
                            getUserMedia: !!navigator.mediaDevices?.getUserMedia
                        }
                    });

                    setError(`
                        Camera access failed. Please ensure:
                        1. You're using a modern browser (Chrome, Safari)
                        2. Your browser is up to date
                        3. You're accessing the site via HTTPS
                        4. You're not in an incognito/private browsing mode
                    `);
                }
            } catch (err) {
                console.error("Permission query error:", err);
                setError("Failed to check camera permissions.");
            }
        };

        // Add permission change listener
        navigator.permissions.query({ name: 'camera' as PermissionName })
            .then(permissionStatus => {
                permissionStatus.onchange = () => {
                    if (permissionStatus.state === 'granted') {
                        window.location.reload();
                    }
                };
            });

        requestPermission();
    }, []);


    const captureImage = () => {
        const capturedImage = webcamRef.current?.getScreenshot();
        if (openFrontCamera) {
            if (capturedImage) {
                setFormData((prev: any) => ({ ...prev, frontImage: capturedImage })); // Save captured image in state
                setOpenFrontCamera(false)
            }
        }
        if (openBackCamera) {
            if (capturedImage) {
                setFormData((prev: any) => ({ ...prev, backImage: capturedImage })); // Save captured image in state
                setOpenBackCamera(false)
            }
        }
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <>
                <div style={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    zIndex: 1000
                }}>
                    <button style={{
                        background: 'none',
                        border: '1px solid #fff',
                        color: 'white',
                        fontSize: '14px',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        width: '23px',
                        height: '23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                        onClick={handleCloseCamera}
                    >✕</button>
                </div>

                <div style={{
                    width: '80%',
                    maxWidth: '300px',
                    margin: '60px auto 20px',
                    position: 'relative'
                }}>
                    <div style={{
                        border: '2px solid #fff',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        aspectRatio: '16/10'
                    }}>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={{
                                facingMode: "environment",
                            }}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                aspectRatio: '16/10'    
                            }}
                        />
                    </div>
                </div>

                <div style={{
                    textAlign: 'center',
                    color: '#fff',
                    padding: '0 20px',
                    marginBottom: '40px'
                }}>
                    <h2 style={{
                        color: '#4CAF50',
                        fontSize: '12px',
                        marginBottom: '10px',
                        fontWeight: '700',
                        fontFamily: 'Inter'
                    }}>
                        Take a photo of the back of your ID
                    </h2>
                    <p style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        fontFamily: 'Inter',
                        margin: '0 10px'
                    }}>
                        Hold up your ID and take a picture. Your entire ID must be in the frame.
                    </p>
                </div>

                <button
                    onClick={captureImage}
                    style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        backgroundColor: '#000',
                        border: '4px solid #fff',
                        cursor: 'pointer',
                        position: 'absolute',
                        bottom: '40px',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}
                >
                    <div style={{
                        width: '48px',
                        height: '48px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                    </div>
                </button>
            </>
            {/* {!image ? (
            ) : (
                <div>
                    <img src={image} alt="Captured ID" style={{ width: "100%", borderRadius: 10 }} />
                    <button
                        onClick={() => setImage(null)} // Reset state to retake image
                        style={{
                            marginTop: "20px",
                            padding: "10px 20px",
                            backgroundColor: "#007BFF",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                    >
                        Retake
                    </button>
                </div>
            )} */}
        </div>
    );
};

export default CameraCustomMobile;
