import React, { useEffect, useRef, useState } from "react";
import { Steps, Layout, Grid } from "antd";
import moment from "moment";
import {
  signIn,
  signUp,
  confirmSignIn,
  fetchAuthSession,
  signOut,
} from "aws-amplify/auth";
import { uploadData } from "aws-amplify/storage/s3";
import Responsive from "../../Hooks/Responsive";
import TransactionType from "../../Components/StepsScreenComponents/TransactionType/Desktop";
import TransactionTypeMobile from "../../Components/StepsScreenComponents/TransactionType/Mobile";
import ProductDetail from "../../Components/StepsScreenComponents/ProductDetail/Desktop";
import ProductDetailMobile from "../../Components/StepsScreenComponents/ProductDetail/Mobile";
import Verification from "../../Components/StepsScreenComponents/Verification/Desktop";
import VerificationMobile from "../../Components/StepsScreenComponents/Verification/Mobile";
import Header from "../../Components/Global/Header";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  addTransaction,
  register_cognito_user,
  resendOtp,
} from "../../actions/CreateTransaction";
import { checkAndSignOut } from "../../actions/Auth";

const { Step } = Steps;
const { Content } = Layout;
const { useBreakpoint } = Grid;

interface ProductFormData {
  code: string;
  producturl: string;
  productname: string;
  productdescribtion: string;
  price: string | number;
  productimages: Array<{
    uid: string;
    file: File;
    preview: string;
  }>;
}

interface VerificationFormData {
  mobilenumber?: string;
  otp?: string;
  name?: string;
  address?: string;
  email?: string;
  actionRequired?: boolean;
}

const CreateTransaction: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkAndSignOut();
  }, []);

  const [selectedRole, setSelectedRole] = useState<string | undefined>(
    undefined
  );

  const [selectedPaymentType, setSelectedPaymentType] = useState<string | null>(
    null
  );

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const deviceType = Responsive();
  const screens = useBreakpoint();

  const [formdata, setformdata] = useState<ProductFormData>({
    code: `${Date.now()}-${Math.random().toString(36).slice(2, 8)}`,
    producturl: "",
    productname: "",
    productdescribtion: "",
    price: "",
    productimages: [],
  });

  async function currentSession() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

      console.log(`The accessToken: ${accessToken}`);
      console.log(`The idToken: ${idToken}`);
    } catch (err) {
      console.log(err);
    }
  }

  const [verificationFormData, setVerificationFormData] =
    useState<VerificationFormData>({
      mobilenumber: "",
      otp: "",
      address: "",
      email: "",
      name: "",
      actionRequired: false,
    });

  const prevDimensions = useRef({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("Step1");
      localStorage.removeItem("Step2");
      localStorage.removeItem("Step3");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const { width: prevWidth, height: prevHeight } = prevDimensions.current;

      if (
        window.innerWidth !== prevWidth ||
        window.innerHeight !== prevHeight
      ) {
        prevDimensions.current = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const verification = localStorage.getItem("verification");
    if (verification) {
      const parsedData = JSON.parse(verification);
      console.log("formdata of verification =====", parsedData);
      setVerificationFormData({
        ...verificationFormData,
        mobilenumber: parsedData?.mobilenumber,
        otp: parsedData?.otp,
        name: parsedData?.name,
        email: parsedData?.email,
        address: parsedData?.address,
      });
    }
  }, []);

  useEffect(() => {
    const productDetail = localStorage.getItem("productdetail");
    if (productDetail) {
      const parsedData = JSON.parse(productDetail);
      setformdata({
        ...formdata,
        price: parsedData?.price,
        productdescribtion: parsedData?.productdescribtion,
        productname: parsedData?.productname,
        producturl: parsedData?.producturl,
        productimages: parsedData?.productimages,
      });
    }
  }, []);

  const steps = [
    {
      title: "Transaction Type",
      content: "Transaction Type",
    },
    {
      title: "Product Details",
      content: "Product Details",
    },
    {
      title: "Share Link",
      content: "Share Link",
    },
  ];

  const [current, setCurrent] = useState<number>(0);
  const next = () => {
    localStorage.setItem(
      "Step1",
      JSON.stringify({
        role: selectedRole,
        paymentType: selectedPaymentType,
      })
    );
    // if (selectedRole === "seller") {
    //   navigate("/request_join_transaction");
    // } else {
    setCurrent(current + 1);
    // }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [otpDisable, setotpDisable] = useState<boolean>(false);

  const handleSendOtp = async () => {
    currentSession();
    if (!verificationFormData?.mobilenumber) {
      return;
    }

    try {
      setIsLoading(true);

      await signUp({
        username: `+${verificationFormData?.mobilenumber}`,
        password: "12345678", // Temporary password
        options: {
          userAttributes: {
            phone_number: `+${verificationFormData?.mobilenumber}`,
            name: `+${verificationFormData?.mobilenumber}`,
          },
        },
      });

      handleLogin(); // Initiate login after sign-up
    } catch (error: any) {
      console.error("Error signing up:", error);

      if (error.message.toLowerCase().includes("already exists")) {
        handleLogin();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await signIn({
        username: `+${verificationFormData?.mobilenumber}`,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
          preferredChallenge: "SMS_OTP",
        },
      });
      setotpDisable(true);
    } catch (error: any) {
      console.error("Error logging in:", error);
      alert(error.message || "Error logging in.");
    } finally {
      //setLoading(false);
      setIsLoading(false);
      setisResendLoading(false);
    }
  };

  const [isLoadingVerification, setIsLoadingVerification] =
    useState<boolean>(false);

  const VerifyOtp = async () => {
    if (!verificationFormData?.mobilenumber || !verificationFormData?.otp) {
      return;
    }
    try {
      setIsLoadingVerification(true);

      const response = await confirmSignIn({
        challengeResponse: verificationFormData?.otp,
      });
      const { nextStep: confirmSignInNextStep } = response;

      if (confirmSignInNextStep.signInStep === "DONE") {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        const res = await register_cognito_user({
          token: accessToken,
          account_type: selectedRole!,
        });
        setIsVerified(true);
        setVerificationFormData({
          ...verificationFormData,
          name: res?.data?.name || "",
          email: res?.data?.email || "",
          address: res?.data?.address || "",
        });
        localStorage.setItem("user", JSON.stringify(res?.data));
      }
    } catch (error: any) {
      console.error("Error:", error);
      alert(error.message || "An error occurred.");
    } finally {
      setIsLoadingVerification(false);
    }
  };

  useEffect(() => {
    if (verificationFormData?.otp && verificationFormData?.otp.length === 6) {
      VerifyOtp();
    }
  }, [verificationFormData.otp]);

  const [isResendLoading, setisResendLoading] = useState<boolean>(false);

  const [transactionLoader, settransactionLoader] = useState(false);

  const add_Transaction = async () => {
    // Parse Step1, Step2, and Step3 data from localStorage

    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const step1 = JSON.parse(localStorage.getItem("Step1") || "{}");
    const step2 = JSON.parse(localStorage.getItem("Step2") || "{}");
    const step3 = JSON.parse(localStorage.getItem("Step3") || "{}");
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    // Create FormData and populate it

    const transactionData = {
      access_token: accessToken,
      type: step1.role,
      product_url: step2.producturl,
      product_title: step2.productname,
      description: step2.productdescribtion,
      price: step2.price,
      name: step3.name,
      email: step3.email,
      delivery_address: step3.address,
      payment_type: step1.paymentType,
      code: step2.code,
    };

    settransactionLoader(true);

    try {
      const response = await addTransaction(transactionData);

      if (response?.data) {
        localStorage.setItem("user", JSON.stringify(response?.user));

        navigate("/create_transaction/transaction_link", {
          state: { selectedRole, url: response?.data?.share_url },
        });
        localStorage.removeItem("Step1");
        localStorage.removeItem("Step2");
        localStorage.removeItem("Step3");
      }
    } catch (error) {
      console.log(error);
    } finally {
      settransactionLoader(false);
    }
  };

  const [formattedDate, setformattedDate] = useState(
    moment().format("YYYY/MM/DD")
  );

  const UploadFile = async (file: File) => {
    const session = await fetchAuthSession();
    console.log(session);
    try {
      const result = await uploadData({
        path: `${formattedDate}/${formdata?.code}/${Date.now()}-${file?.name}`,
        data: file,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
          },
          bucket: {
            bucketName: "trustpay-temp-bucket",
            region: "us-east-1",
          },
        },
      }).result;

      setformdata((prevData) => {
        const currentImages = Array.isArray(prevData.productimages)
          ? prevData.productimages
          : [];

        const newFiles = [
          {
            uid: result.path,
            file,
            preview: `https://trustpay-temp-bucket.s3.us-east-1.amazonaws.com/${result.path}`,
          },
        ];

        const updatedImages = [...currentImages, ...newFiles];
        return {
          ...prevData,
          productimages: updatedImages,
        };
      });
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  useEffect(() => {
    let savedUser = JSON.parse(localStorage.getItem("user") || "{}");
    // if(savedUser)
    if (Object.keys(savedUser).length > 0) {
      // setVerificationFormData()
      setVerificationFormData({
        ...verificationFormData,
        name: savedUser?.name,
        email: savedUser?.email,
        address: savedUser?.address,
      });
    }
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />

      <Layout
        style={{
          flex: 1,
          overflowX: "hidden",
          backgroundColor: "#F5F5F5",
        }}
      >
        {/* Scrollable Content */}
        <Content style={{ overflowY: "auto", padding: "15px", flexGrow: 1 }}>
          <Steps
            direction="horizontal"
            className="ant-steps-item-process"
            current={current}
            items={items}
            style={{
              marginBottom: "20px",
              display: "flex",
              overflowX: "auto", // Add horizontal scroll if needed
              width: "100%",
              fontFamily: "Inter",
              fontWeight: 400,
            }}
            size={screens.xs ? "small" : "default"}
            responsive={false} // This prevents responsive behavior
          />

          {current === 0 &&
            (deviceType === "desktop" ? (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "desktop" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <TransactionType
                  advanceSelected={selectedPaymentType === "advance"}
                  fullSelected={selectedPaymentType === "full"}
                  paymentAdvanceOnChange={() =>
                    setSelectedPaymentType("advance")
                  }
                  paymentFullOnChange={() => setSelectedPaymentType("full")}
                  onClick={() => {
                    next();
                  }}
                  disabled={!selectedRole || !selectedPaymentType}
                  sellerselected={selectedRole === "seller"}
                  buyerselected={selectedRole === "buyer"}
                  sellerOnChange={() => setSelectedRole("seller")}
                  buyerOnChange={() => setSelectedRole("buyer")}
                />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "mobile" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <TransactionTypeMobile
                  advanceSelected={selectedPaymentType === "advance"}
                  fullSelected={selectedPaymentType === "full"}
                  paymentAdvanceOnChange={() =>
                    setSelectedPaymentType("advance")
                  }
                  paymentFullOnChange={() => setSelectedPaymentType("full")}
                  onClick={() => {
                    next();
                  }}
                  disabled={!selectedPaymentType || !selectedRole}
                  sellerselected={selectedRole === "seller"}
                  buyerselected={selectedRole === "buyer"}
                  sellerOnChange={() => setSelectedRole("seller")}
                  buyerOnChange={() => setSelectedRole("buyer")}
                />
              </motion.div>
            ))}

          {current === 1 &&
            (deviceType === "desktop" ? (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "desktop" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <ProductDetail
                  onClick={next}
                  onbackClick={prev}
                  formdata={formdata}
                  setformdata={setformdata}
                />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "mobile" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <ProductDetailMobile
                  onClick={next}
                  onbackClick={prev}
                  formdata={formdata}
                  setformdata={setformdata}
                  onUpload={(file: File) => {
                    //  console.log(file);
                    UploadFile(file);
                  }}
                />
              </motion.div>
            ))}

          {current === 2 &&
            (deviceType === "desktop" ? (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "desktop" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Verification
                  onbackClick={prev}
                  verificationformdata={verificationFormData}
                  setverificationformdata={setVerificationFormData}
                  selectedRole={selectedRole}
                />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "mobile" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <VerificationMobile
                  onbackClick={prev}
                  verificationformdata={verificationFormData}
                  setverificationformdata={setVerificationFormData}
                  selectedRole={selectedRole}
                  isVerified={isVerified}
                  sendOtp={() => {
                    handleSendOtp();
                  }}
                  otpDisable={otpDisable}
                  setOtpDisable={setotpDisable}
                  isLoading={isLoading}
                  verifyOtp={() => {
                    VerifyOtp();
                  }}
                  isLoadingVerification={isLoadingVerification}
                  resendOtp={() => {
                    setisResendLoading(true);
                    handleLogin();
                  }}
                  isResendLoading={isResendLoading}
                  create_transaction={add_Transaction}
                  transactionLoading={transactionLoader}
                />
              </motion.div>
            ))}
        </Content>
      </Layout>
    </div>
  );
};

export default CreateTransaction;
