import React, { useState, useEffect } from "react";
import { list } from "aws-amplify/storage/s3";
import { useLocation } from "react-router-dom";
import Header from "../../../Components/Global/Header";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Responsive from "../../../Hooks/Responsive";
import RequestTransactionMobile from "../../../Components/SellerStepComponents/JoinTransaction/Mobile";
import { useNavigate } from "react-router-dom";
import { get_Transaction_By_Code } from "../../../actions/CreateTransaction";
import { checkAndSignOut } from "../../../actions/Auth";

const JoinTransaction: React.FC = () => {
  const deviceType = Responsive();
  const navigate = useNavigate();

  const location = useLocation();
  const [requestValue, setRequestValue] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const request = queryParams.get("request") || "";
    setRequestValue(request);
  }, [location]);

  const [formData, setFormData] = useState({
    seller_invite_terms: false,
  });

  const handleTermsChange = (checked: boolean) => {
    setFormData({ ...formData, seller_invite_terms: checked });
  };

  const [Loading, setLoading] = useState(false);
  const [request, setrequest] = useState(null);

  const getTransactionByCode = async () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    // Create FormData and populate it

    setLoading(true);

    try {
      const response = await get_Transaction_By_Code({ code: requestValue });
      setrequest(response?.data);
      console.log(response?.data?.user_id, user?.id, user?.is_buyer);
      if (response?.data?.user_id === user?.id && user?.is_buyer == "1") {
        return navigate(`/buyer_waiting?request=${requestValue}`, {
          state: { request: response?.data },
        });
      } else {
        checkAndSignOut();
        getAllfilesInFolder(response?.data?.path);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (requestValue) {
      getTransactionByCode();
    }
  }, [requestValue]);

  // Define the type for each item in the result
  interface FileItem {
    path: string;
  }

  // Define the state type for files
  const [files, setFiles] = useState<string[]>([]);

  const getAllfilesInFolder = async (path: string) => {
    try {
      const result = await list({
        path: path, //`product_images/${requestValue}/`,
      });

      // Ensure the result has an 'items' array and it's an array of FileItem
      if (result.items && Array.isArray(result.items)) {
        const newFiles: string[] = result.items.map((item: FileItem) => {
          const previewUrl = `https://trustpay-temp-bucket.s3.us-east-1.amazonaws.com/${item.path}`;
          return previewUrl;
        });

        // Update the state with the newly generated files
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };
  //   const add_Transaction = async () => {
  //     // Parse Step1, Step2, and Step3 data from localStorage
  //     const step1 = JSON.parse(localStorage.getItem("Step1") || "{}");
  //     const step2 = JSON.parse(localStorage.getItem("Step2") || "{}");
  //     const step3 = JSON.parse(localStorage.getItem("Step3") || "{}");
  //     const user = JSON.parse(localStorage.getItem("user") || "{}");

  //     // Create FormData and populate it

  //     const transactionData = {
  //       user_id: user?.id,
  //       type: step1.role,
  //       product_url: step2.producturl,
  //       product_title: step2.productname,
  //       description: step2.productdescribtion,
  //       price: step2.price,
  //       name: step3.name,
  //       email: step3.email,
  //       delivery_address: step3.address,
  //       payment_type: step1.paymentType,
  //       // product_image_ids: "1,2,3",
  //     };

  //     settransactionLoader(true);

  //     try {
  //       const response = await addTransaction(transactionData);

  //       if (response?.data) {
  //         window.alert(response?.data?.share_url);
  //         navigate("/create_transaction/transaction_link", {
  //           state: { selectedRole, url: response?.data?.share_url },
  //         });
  //       }
  //       // if (response?.otp) {
  //       //   window.alert(response?.otp);
  //       // }
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       settransactionLoader(false);
  //     }
  //   };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />

      <Layout
        style={{
          flex: 1,
          overflowX: "hidden",
          backgroundColor: "#F5F5F5",
        }}
      >
        <Content style={{ overflowY: "auto", padding: "15px", flexGrow: 1 }}>
          {Loading ? "" : null}
          {deviceType === "desktop" && request ? (
            <RequestTransactionMobile
              request={request}
              handleTermsChange={handleTermsChange}
              setFormData={setFormData}
              formData={formData}
              onclick={() => {
                navigate("/request_join_transaction/join_transaction", {
                  state: { code: requestValue },
                });
              }}
              files={files}
            />
          ) : (
            request && (
              <RequestTransactionMobile
                request={request}
                handleTermsChange={handleTermsChange}
                setFormData={setFormData}
                formData={formData}
                onclick={() => {
                  navigate("/request_join_transaction/join_transaction", {
                    state: { code: requestValue },
                  });
                }}
                files={files}
              />
            )
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default JoinTransaction;
