import React from "react";

interface ProductCardProps {
  title: string;
  price: string;
  description: string;
  images: string[];
}

const ProductCardMobile: React.FC<ProductCardProps> = ({
  title,
  price,
  description,
  images,
}) => {
  return (
    <div style={styles.card}>
      <h3 style={styles.title}>{title}</h3>
      <div style={styles.imageContainer}>
        {images &&
          images.length > 0 &&
          images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`${title}-${index}`}
              style={styles.image}
            />
          ))}
      </div>

      <div style={styles.content}>
        <p style={styles.description}>{description}</p>
        <p style={styles.price}>LKR {price.toLocaleString()}</p>
      </div>
    </div>
  );
};

const styles = {
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    padding: "12px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "30px",
  },
  imageContainer: {
    display: "flex",
    overflowX: "auto" as const,
    gap: "8px",
    marginBottom: "12px",
  },
  image: {
    width: "100px",
    height: "100px",
    objectFit: "cover" as const,
    borderRadius: "4px",
  },
  content: {
    padding: "4px",
  },
  title: {
    fontSize: "14px",
    marginTop: "10px",
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#000000",
  },
  description: {
    fontSize: "12px",
    color: "#000000",
    margin: "0 0 8px 0",
    fontFamily: "Inter",
    fontWeight: "400",
  },
  price: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#000",
    margin: "0",
    fontFamily: "Inter",
  },
};

export default ProductCardMobile;
