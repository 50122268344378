import React from "react";

interface IconButtonProps {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  className?: string;
}

const IconButtonMobile: React.FC<IconButtonProps> = ({
  icon,
  label,
  onClick,
  className
}) => {
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: "#9FE870",
        borderRadius: "100px",
        height: "36px",
        width: "100%",
      }}
      onClick={onClick}
    >
      <div style={{ fontSize: "24px", marginBottom: "7px", marginRight: '6px', color: "#163300" }}>{icon}</div>
      <span style={{ fontSize: "12px", color: "#163300", fontWeight: "700", fontFamily: "Inter" }}>{label}</span>
    </div>
  );
};

export default IconButtonMobile;
