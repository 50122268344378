import React from "react";
import logo from "../../../assets/Images/header_logo.png";


const Header: React.FC = () => {
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "60px", backgroundColor: "#F5F5F5" }}>
            <img src={logo} alt="logo" style={{ width: "100px" }} />
        </div>
    );
};

export default Header;
