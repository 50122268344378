import { base } from "antd-mobile/es/locales/base";

export const baseUrl: string = "https://trustpayapi.dotserviz.co/api/";

export const api: Record<string, string> = {
  sendOtp: `${baseUrl}v2/users/login`,
  verifyOtp: `${baseUrl}v2/users/verify_otp`,
  resendOtp: `${baseUrl}v2/users/resend_otp`,
  create_transaction: `${baseUrl}cognito_auth/create_transaction`,
  get_tranaction_by_code: `${baseUrl}v2/transactions/get_tranaction_by_code`,
  update_transaction: `${baseUrl}cognito_auth/update_seller_info`,
  register_user: `${baseUrl}cognito_auth/get_user`,
};
