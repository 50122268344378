import React from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input"; // Importing InputProps from Ant Design for input element types
import { ReactNode } from "react";

interface CustomInputProps extends InputProps {
  prefix?: ReactNode; // Prefix can be a ReactNode (e.g., icon or string)
  controls?: boolean; // Custom control flag (if needed)
  style?: React.CSSProperties; // Inline style object
}

const CustomInput: React.FC<CustomInputProps> = ({
  placeholder,
  value,
  onChange,
  prefix,
  type,
  controls,
  style,
  ...props
}) => {
  const inputMode = type === "number" ? "numeric" : undefined;

  return (
    <Input
      {...props}
      type={type}
      style={{
        ...style,
        height: "43px",
        flex: 1,
        borderRadius: "8px",
        borderColor: "#ccc",
        appearance: type === "number" ? "textfield" : "none",
        padding: "0 15px",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 400,
        outline: "none",
      }}
      className="custom-input-no-outline"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      prefix={prefix}
      onFocus={(e) => (e.target.style.borderColor = "lightgreen")}
      onBlur={(e) => (e.target.style.borderColor = "#ccc")}
      inputMode={inputMode}
    />
  );
};

export default CustomInput;
