import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DownOutline, UpOutline } from 'antd-mobile-icons';

interface CustomDropdownProps {
    options: string[];
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}

const CustomDropdownMobile: React.FC<CustomDropdownProps> = ({
    options,
    value,
    onChange,
    placeholder = "Select"
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    padding: '8px 12px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    cursor: 'pointer',
                    minHeight: '35px',
                    marginTop: '10px'
                }}
            >
                <span style={{
                    fontSize: '12px',
                    color: value ? '#000000' : '#8E8E93',
                    fontFamily: 'Inter'
                }}>
                    {value || placeholder}
                </span>
                {!isOpen ? <DownOutline style={{ fontSize: '12px', color: '#ccc' }} />
                    : <UpOutline style={{ fontSize: '12px', color: '#ccc' }} />}
            </div>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            right: 0,
                            maxHeight: '200px',
                            overflowY: 'auto',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            zIndex: 1000,
                            marginTop: '4px'
                        }}
                    >
                        {options.map((option) => (
                            <div
                                key={option}
                                onClick={() => {
                                    onChange(option);
                                    setIsOpen(false);
                                }}
                                style={{
                                    padding: '10px 12px',
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    backgroundColor: value === option ? '#F2F2F7' : 'white',
                                    color: value === option ? '#007AFF' : '#000000',
                                    fontFamily: 'Inter'
                                }}
                            >
                                {option}
                            </div>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CustomDropdownMobile;