import React from "react";
import { Grid, Input } from "antd";
import { InputProps } from "antd/lib/input"; // Importing InputProps from Ant Design for input element types
import { ReactNode } from "react";

import hand_outline from "../../../assets/Images/hand_outline.png"
import chat_outline from "../../../assets/Images/chat_outline.png"
import shop_outline from "../../../assets/Images/shop_outline.png"
import profile_outline from "../../../assets/Images/profile_outline.png"
import hand_fill from "../../../assets/Images/hand_fill.png"
import chat_fill from "../../../assets/Images/chat_fill.png"
import shop_fill from "../../../assets/Images/shop_fill.png"
import profile_fill from "../../../assets/Images/profile_fill.png"

interface BottomBarProps {
    onStartTransactionClick: () => void;
    onMyTransactionsClick: () => void;
    onMyListingsClick: () => void;
    onProfileClick: () => void;
    activeTab: string;
}

const BottomBar: React.FC<BottomBarProps> = ({
    onStartTransactionClick,
    onMyTransactionsClick,
    onMyListingsClick,
    onProfileClick,
    activeTab
}) => {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    return (
        <div style={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            height: "53px",
            backgroundColor: "#ffffff",
            borderColor: "#ccc",
            appearance: "none",
            fontSize: "14px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "0px -1px 10px 0px rgba(0, 0, 0, 0.1)"
        }}>
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={onStartTransactionClick}>
                <div style={{ width: screens.xs ? "80%" : "50%", height: screens.xs ? "70%" : "50%", display: "flex", flexDirection: screens.xs ? "column" : "row", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                    <img src={activeTab === "startTransaction" ? hand_fill : hand_outline} alt="icon" style={{ width: screens.xs ? "20px" : "30px", height: screens.xs ? "20px" : "30px", marginTop: screens.xs ? "3px" : "0px" }} />
                    <p style={{ fontSize: screens.xs ? "8px" : "12px", marginLeft: screens.xs ? "0px" : "5px", marginTop: screens.xs ? "2px" : "15px", fontFamily: "Inter", fontWeight: 400, }}>Start Transaction</p>
                </div>
            </div>

            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={onMyTransactionsClick}>
                <div style={{ width: screens.xs ? "80%" : "50%", height: screens.xs ? "70%" : "50%", display: "flex", flexDirection: screens.xs ? "column" : "row", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                    <img src={activeTab === "myTransactions" ? chat_fill : chat_outline} alt="icon" style={{ width: screens.xs ? "20px" : "30px", height: screens.xs ? "20px" : "30px", marginTop: screens.xs ? "3px" : "0px" }} />
                    <p style={{ fontSize: screens.xs ? "8px" : "12px", marginLeft: screens.xs ? "0px" : "5px", marginTop: screens.xs ? "2px" : "15px", fontFamily: "Inter", fontWeight: 400, }}>My Transactions</p>
                </div>
            </div>

            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={onMyListingsClick}>
                <div style={{ width: screens.xs ? "80%" : "50%", height: screens.xs ? "70%" : "50%", display: "flex", flexDirection: screens.xs ? "column" : "row", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                    <img src={activeTab === "myLisitings" ? shop_fill : shop_outline} alt="icon" style={{ width: screens.xs ? "20px" : "30px", height: screens.xs ? "20px" : "30px", marginTop: screens.xs ? "3px" : "0px" }} />
                    <p style={{ fontSize: screens.xs ? "8px" : "12px", marginLeft: screens.xs ? "0px" : "5px", marginTop: screens.xs ? "2px" : "15px", fontFamily: "Inter", fontWeight: 400, }}>My Listings</p>
                </div>
            </div>

            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={onProfileClick}>
                <div style={{ width: screens.xs ? "80%" : "50%", height: screens.xs ? "70%" : "50%", display: "flex", flexDirection: screens.xs ? "column" : "row", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                    <img src={activeTab === "profile" ? profile_fill : profile_outline} alt="icon" style={{ width: screens.xs ? "20px" : "30px", height: screens.xs ? "20px" : "30px", marginTop: screens.xs ? "3px" : "0px" }} />
                    <p style={{ fontSize: screens.xs ? "8px" : "12px", marginLeft: screens.xs ? "0px" : "5px", marginTop: screens.xs ? "2px" : "15px", fontFamily: "Inter", fontWeight: 400, }}>Profile</p>
                </div>
            </div>
        </div>
    );
};

export default BottomBar;
