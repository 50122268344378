import { fetchAuthSession, signOut } from "aws-amplify/auth";

export const checkAndSignOut = async () => {
  try {
    // Fetch the current authentication session
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    console.log("idToken", idToken);
    console.log("accessToken", idToken);

    if (accessToken) {
      console.log("User is logged in. Signing out...");
      await signOut();
      localStorage.removeItem("user");
      console.log("User signed out successfully.");
    } else {
      console.log("No active session found.");
    }
  } catch (error) {
    console.error("Error checking session or signing out:", error);
  }
};
