import { Button } from "antd-mobile";
import React from "react";

// Define the types for the props
interface CustomButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  fill?: "solid" | "outline";
  style?: React.CSSProperties;
  className?: string;
  loading?: boolean;
}

// Custom Button Component
const CustomButtonMobile: React.FC<CustomButtonProps> = ({
  label,
  onClick,
  disabled = false,
  fill = "solid",
  style,
  className,
  loading,
}) => {
  return (
    <Button
      fill="solid"
      block
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={{
        height: "35px",
        marginTop: "16px",
        borderRadius: "100px",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 700,
        background:
          fill === "outline" ? "transparent" : disabled ? "#E3EADE" : "#9FE870",
        color:
          fill === "outline" ? "#163300" : disabled ? "#99A093" : "#163300",
        border: `1px solid ${
          fill === "outline" ? "#163300" : disabled ? "#E3EADE" : "#9FE870"
        }`,
        cursor: disabled ? "not-allowed" : "pointer",

        ...style,
      }}
    >
      {loading ? "Loading..." : label}
    </Button>
  );
};

export default CustomButtonMobile;
