import { Card } from "antd";
import React from "react";
import { ShoppingCartOutlined, CreditCardOutlined } from "@ant-design/icons";
import SelectableButton from "../../Global/SelectableButton/Desktop";
import CustomButton from "../../Global/CustomButton/Desktop";
import color from "../../../Utils/color";

// Define the types for the props
interface TransactionTypeProps {
  sellerselected: boolean;
  buyerselected: boolean;
  sellerOnChange: () => void;
  buyerOnChange: () => void;
  onClick: () => void;
  disabled: boolean;
  advanceSelected: boolean;
  fullSelected: boolean;
  paymentAdvanceOnChange: () => void;
  paymentFullOnChange: () => void;
}

const TransactionType: React.FC<TransactionTypeProps> = ({
  buyerselected,
  buyerOnChange,
  sellerselected,
  sellerOnChange,
  onClick,
  disabled,
  advanceSelected,
  fullSelected,
  paymentAdvanceOnChange,
  paymentFullOnChange,
}) => {
  return (
    <Card
      style={{
        borderRadius: "18px",
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
      }}
    >
      <h2 style={{ textAlign: "center", fontWeight: "700", fontFamily: "Inter", }}>Create a New Transaction</h2>
      < p style={{ marginBottom: "5px", fontWeight: "700", fontFamily: "Inter", }}>
        I am a <span style={{ color: "red" }}>*</span>
      </p>
      <SelectableButton
        icon={
          <ShoppingCartOutlined
            style={{
              fontSize: "20px",
              color: buyerselected ? color.primary : "#e8e8e8",
            }}
          />
        }
        label="Buyer"
        onChange={buyerOnChange}
        selected={buyerselected}
        radioclick={buyerOnChange}
      />

      <SelectableButton
        icon={
          <ShoppingCartOutlined
            style={{
              fontSize: "20px",
              color: sellerselected ? color.primary : "#e8e8e8",
            }}
          />
        }
        label="Seller"
        onChange={sellerOnChange}
        selected={sellerselected}
        radioclick={sellerOnChange}
      />

      <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
        Payment Type
      </p>
      <SelectableButton
        icon={
          <CreditCardOutlined
            style={{
              fontSize: "20px",
              color: advanceSelected ? color.primary : "#e8e8e8",
            }}
          />
        }
        label="Advance Payment"
        onChange={paymentAdvanceOnChange}
        selected={advanceSelected}
        radioclick={paymentAdvanceOnChange}
      />

      <SelectableButton
        icon={
          <CreditCardOutlined
            style={{
              fontSize: "20px",
              color: fullSelected ? color.primary : "#e8e8e8",
            }}
          />
        }
        label="Full Payment"
        onChange={paymentFullOnChange}
        selected={fullSelected}
        radioclick={paymentFullOnChange}
      />

      <div style={{ marginTop: "5%" }}>
        <CustomButton
          label="Next"
          onClick={onClick}
          type="default"
          disabled={disabled}
        />
      </div>
    </Card >
  );
};

export default TransactionType;
