import React, { useEffect, useState } from "react";
import Header from "../../../Components/Global/Header";
import { Layout, Button, Input, Grid, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import Responsive from "../../../Hooks/Responsive";
import BuyerWaitingComponent from "../../../Components/Global/BuyerWaiting/Mobile";
import { useNavigate } from "react-router-dom";
import BottomBar from "../../../Components/Global/BottomBar";
import product_one from "../../../assets/Images/product_one.png";
import product_two from "../../../assets/Images/product_two.png";
import CustomButtonMobile from "../../../Components/Global/CustomButton/Mobile";
import { useLocation } from "react-router-dom";
import toast from "antd-mobile/es/components/toast";
import ShareOnSocial from "react-share-on-social";

const BuyerWaiting: React.FC = () => {
  const deviceType = Responsive();
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const location = useLocation();
  const { request, files } = location.state;

  const [data, setData] = useState<{
    status: "" | "pending" | "success" | "error";
    message: string;
  }>({
    status: "",
    message: "",
  });

  const steps = [
    { id: 1, label: "Waiting for your payment" },
    { id: 2, label: "Buyer Confirms" },
    { id: 3, label: "Funds release" },
  ];

  const productData = {
    title: "Chanel Trainers UK size 5",
    price: "LKR. 170,000.00",
    description:
      "Iconic authentic Chanel trainers sneakers size UK 5. Comes with the original box",
    images: [product_one, product_two, product_one],
  };

  // Add copy handler
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(request?.share_url);
      toast.show("Link copied to clipboard!");
    } catch (error) {
      console.error("Error copying text:", error);
      // Fallback method for copying
      const textArea = document.createElement("textarea");
      textArea.value = request?.share_url;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        toast.show("Link copied to clipboard!");
      } catch (err) {
        toast.show("Failed to copy link");
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <>
        <Header />

        <Layout
          style={{
            flex: 1,
            overflowX: "hidden",
            backgroundColor: "#F5F5F5",
          }}
        >
          {/* Scrollable Content */}
          <Content
            style={{
              overflowY: "auto",
              padding: "15px 15px 85px 15px",
              flexGrow: 1,
            }}
          >
            {deviceType === "desktop" ? (
              <BuyerWaitingComponent
                currentStep={1}
                completedSteps={[0]}
                steps={steps}
                productData={productData}
              />
            ) : (
              <BuyerWaitingComponent
                currentStep={request?.seller_id !== null ? 1 : 0}
                completedSteps={[0]}
                steps={steps}
                productData={request}
              />
            )}

            {request?.seller_id === null ? (
              <>
                <p
                  style={{
                    marginBottom: "12px",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Your Transaction Link
                </p>
                <Input
                  readOnly
                  value={request?.share_url}
                  style={{
                    marginBottom: "16px",
                    borderRadius: "8px",
                    fontSize: "16px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    justifyContent: "center",
                  }}
                >
                  <CustomButtonMobile
                    fill="outline"
                    label="Copy Link"
                    onClick={() => {
                      handleCopy();
                    }}
                  />
                  <ShareOnSocial
                    shareTo={["facebook", "whatsapp", "twitter", "email"]}
                    textToShare="Share Transaction Link"
                    link={request?.share_url}
                    linkFavicon="https://api.keyp.co.za/storage/favicon.ico"
                    noReferer
                    sx={{ cursor: "pointer" }} // This makes the entire ShareOnSocial component clickable with a hand cursor
                  >
                    <CustomButtonMobile
                      fill="solid"
                      label="Share Link"
                      onClick={() => {}}
                    />
                  </ShareOnSocial>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "Inter",
                      color: "#000000",
                      width: "95px",
                      margin: 0,
                    }}
                  >
                    Price
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#000000",
                      flex: 1,
                      margin: 0,
                      fontWeight: "400",
                      fontFamily: "Inter",
                      textAlign: "right",
                    }}
                  >
                    LKR. {request?.price?.toLocaleString()}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "Inter",
                      color: "#000000",
                      width: "95px",
                      margin: 0,
                    }}
                  >
                    Buyer Protection
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#000000",
                      flex: 1,
                      margin: 0,
                      fontWeight: "400",
                      fontFamily: "Inter",
                      textAlign: "right",
                    }}
                  >
                    {request?.price > 10000
                      ? `LKR. ${(request?.price * 0.02).toLocaleString()}`
                      : "LKR. 200.00"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    marginTop: "10%",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Inter",
                      color: "#000000",
                      margin: "0 10px 0 0",
                    }}
                  >
                    Total Price :
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Inter",
                      color: "#000000",
                      flex: 1,
                      margin: 0,
                      textAlign: "right",
                    }}
                  >
                    LKR.{" "}
                    {(request?.price > 10000
                      ? request?.price + request?.price * 0.02
                      : request?.price + 200
                    ).toLocaleString()}
                  </p>
                </div>

                <CustomButtonMobile
                  label="Proceed to Payment"
                  onClick={() => {
                    // handleSubmit();
                  }}
                  // disabled={!formdata.seller_invite_terms}
                  style={{
                    width: "100%",
                    fontSize: "12px",
                    marginTop: "30px",
                  }}
                />
              </>
            )}

            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                marginTop: "30px",
              }}
            >
              <a
                href="#"
                style={{
                  color: "#000000",
                  textDecorationLine: "underline",
                  fontWeight: "400",
                  fontFamily: "Inter",
                }}
              >
                Need Help? Contact Support
              </a>
            </span>
          </Content>

          <BottomBar
            onStartTransactionClick={() => {}}
            onMyTransactionsClick={() => {}}
            onMyListingsClick={() => {}}
            onProfileClick={() => {}}
            activeTab="myTransactions"
          />
        </Layout>
      </>
    </div>
  );
};

export default BuyerWaiting;
