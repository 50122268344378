import React, { useState } from "react";
import { Amplify } from "aws-amplify";

import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import CreateTransaction from "./Pages/StepScreen";
import TransactionLinkScreen from "./Pages/StepScreen/TransactionLinkScreen";
import SellerStepScreen from "./Pages/SellerScreens/JoinTransaction";
import TransactionForm from "./Pages/SellerScreens/Verification";
import SellerJoinTransaction from "./Pages/SellerScreens/SellerJoinTransaction/SellerJoinTransaction";
import ProceedPayment from "./Pages/StepScreen/ProceedPayment";
import BuyerWaiting from "./Pages/StepScreen/BuyerWaiting";

// Configure Amplify with your Cognito details
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "3s4gc9itiju0l8inqi4psqeghf",
      userPoolId: "us-east-1_qzFqZ2duz",
      loginWith: {
        phone: true, // Optional
        username: true,
      },
      allowGuestAccess: true,
      identityPoolId: "us-east-1:b4b4c6a4-bf7c-4a97-a932-1b91d8e678c8",
    },
  },
  Storage: {
    S3: {
      bucket: "trustpay-temp-bucket",
      region: "us-east-1",
    },
  },
});

const App: React.FC = () => {
  const handleButtonClick = () => {
    console.log("Button clicked");
  };
  const [selected, setSelected] = useState<boolean>(false);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/create_transaction" />} />
        <Route path="/create_transaction" element={<CreateTransaction />} />
        <Route path="/buyer_waiting" element={<BuyerWaiting />} />
        <Route
          path="/create_transaction/transaction_link"
          element={<TransactionLinkScreen />}
        />
        <Route path="/proceed_payment" element={<ProceedPayment />} />

        {/* seller side screen routes */}
        <Route
          path="/request_join_transaction"
          element={<SellerStepScreen />}
        />
        <Route
          path="request_join_transaction/join_transaction"
          element={<TransactionForm />}
        />
        <Route path="/my_transaction" element={<SellerJoinTransaction />} />
      </Routes>
    </Router>
  );
};

export default App;
