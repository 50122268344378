import React, { useState } from "react";
import Header from "../../../Components/Global/Header";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Responsive from "../../../Hooks/Responsive";
import SellerJoinTransactionMobile from "../../../Components/Global/SellerStep/Mobile";
import { useNavigate } from "react-router-dom";
import BottomBar from "../../../Components/Global/BottomBar";
import product_one from "../../../assets/Images/product_one.png";
import product_two from "../../../assets/Images/product_two.png";
import CameraCustomMobile from "../../../Components/Global/CameraCustom/Mobile";
import ProceedPaymentMobile from "../../../Components/Global/ProceedPayment/Mobile";
import { baseUrl } from "../../../constants/api";

// Define the structure for the payment object
interface Payment {
  sandbox: boolean;
  merchant_id: string;
  return_url?: string;
  cancel_url?: string;
  notify_url: string;
  order_id: string;
  items: string;
  amount: string;
  currency: string;
  hash: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  country: string;
  delivery_address: string;
  delivery_city: string;
  delivery_country: string;
  custom_1?: string;
  custom_2?: string;
  settings?: object;
}

declare global {
  interface Window {
    payhere: any;
  }
}

// Define the function's parameter types
interface GenerateHashParams {
  amount: number;
}

const ProceedPayment: React.FC = () => {
  const deviceType = Responsive();
  const navigate = useNavigate();

  const [data, setData] = useState<{
    status: "" | "pending" | "success" | "error";
    message: string;
  }>({
    status: "",
    message: "",
  });
  const [openFrontCamera, setOpenFrontCamera] = useState(false);
  const [openBackCamera, setOpenBackCamera] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formdata, setFormData] = useState({});

  const steps = [
    { id: 1, label: "Funds in TrustPay hold" },
    { id: 2, label: "Buyer Confirms" },
    { id: 3, label: "Funds release" },
  ];

  const productData = {
    title: "Chanel Trainers UK size 5",
    price: "LKR. 170,000.00",
    description:
      "Iconic authentic Chanel trainers sneakers size UK 5. Comes with the original box",
    images: [product_one, product_two, product_one],
  };

  const handleSubmit = () => {
    setData({
      status: "success",
      message: "Your information has been submitted successfully",
    });
  };

  // Generate hash function

  const generateHash = async ({
    amount,
  }: GenerateHashParams): Promise<string | null> => {
    try {
      // Sending the GET request to your backend
      const response = await fetch(
        baseUrl +
          `v1/payment_hash?merchantSecret=MjQzMjU0MzI1NTEwMjMxNjYwNDQ0MTk4MjkzMDYzMzk0ODI0OTE2Mw==&merchantId=1227501&orderId=11223&amount=1000&currency=LKR`
      );

      // Check if the response is ok
      if (!response.ok) {
        throw new Error("Failed to fetch payment hash");
      }

      // Getting the hash from the response
      const { hash } = await response.json();

      return hash; // Return the hash as string
    } catch (error) {
      console.error("Error fetching payment hash:", error);
      return null; // Return null in case of an error
    }
  };

  const handleGenerateHash = () => {
    const params: GenerateHashParams = {
      amount: 1000,
    };
    const hash = generateHash(params);

    return hash;
  };

  const handlePayment = async () => {
    let hash = await handleGenerateHash();
    const payment: Payment = {
      sandbox: true, // Use sandbox mode for testing
      merchant_id: "1227501", // Replace with your Merchant ID
      return_url: "https://dotserviz.co/proceed_payment", // Optional
      cancel_url: "https://dotserviz.co/proceed_payment", // Optional
      notify_url: "https://play.svix.com/in/e_PAWk487yVLKGIZv5zSHwdHeAiOl/", // Replace with your notify URL
      order_id: "11223",
      items: "Trust PAY",
      amount: "1000",
      currency: "LKR",
      hash: hash || "abcd",
      first_name: "Saman",
      last_name: "Perera",
      email: "samanp@gmail.com",
      phone: "0771234567",
      address: "No.1, Galle Road",
      city: "Colombo",
      country: "Sri Lanka",
      delivery_address: "No. 46, Galle road, Kalutara South",
      delivery_city: "Kalutara",
      delivery_country: "Sri Lanka",
      custom_1: "",
      custom_2: "",
    };

    // Ensure PayHere script is loaded and available
    if (window.payhere) {
      // Set up event listeners
      window.payhere.onCompleted = function (orderId: string) {
        console.log("Payment completed. OrderID:", orderId);
        if (orderId) {
          setCurrentStep(currentStep + 1);
        }
        // Add your logic for successful payments here
      };

      window.payhere.onDismissed = function () {
        console.log("Payment dismissed");
        // Add your logic for dismissed payments here
      };

      window.payhere.onError = function (error: string) {
        console.error("Error:", error);
        // Add your logic for errors here
      };

      // Start the PayHere payment process
      window.payhere.startPayment(payment);
    } else {
      console.error("PayHere script not loaded");
    }
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header />

      <Layout
        style={{
          flex: 1,
          overflowX: "hidden",
          backgroundColor: "#F5F5F5",
        }}
      >
        {/* Scrollable Content */}
        <Content
          style={{
            overflowY: "auto",
            padding: "15px 15px 85px 15px",
            flexGrow: 1,
          }}
        >
          <ProceedPaymentMobile
            currentStep={currentStep}
            completedSteps={[currentStep - 1]}
            steps={steps}
            productData={productData}
            formdata={formdata}
            setFormData={setFormData}
            handleOpenFrontCamera={() => setOpenFrontCamera(true)}
            handleOpenBackCamera={() => setOpenBackCamera(true)}
            data={data}
            handleSubmit={handlePayment}
          />
        </Content>
        <BottomBar
          onStartTransactionClick={() => {}}
          onMyTransactionsClick={() => {}}
          onMyListingsClick={() => {}}
          onProfileClick={() => {}}
          activeTab="myTransactions"
        />
      </Layout>
    </div>
  );
};

export default ProceedPayment;
