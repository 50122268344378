import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { Card, Checkbox, Input, Space, Modal } from "antd-mobile";
import CustomInputMobile from "../../Global/CustomInput/Mobile";
import CustomButtonMobile from "../../Global/CustomButton/Mobile";
import MultilinesInputMobile from "../../Global/MultilinesInput/Mobile";
import { CheckCircleOutlined } from "@ant-design/icons";
import success from "../../../assets/Images/check_broken.png";
import { motion } from "framer-motion";

interface VerificationFormData {
  mobilenumber?: string;
  otp?: string;
  name?: string;
  address?: string;
}
interface VerificationMobileProps {
  onbackClick: () => void;
  selectedRole?: string;
  verificationformdata: {
    mobilenumber?: string;
    otp?: string;
    name?: string;
    address?: string;
  };
  setverificationformdata: React.Dispatch<
    React.SetStateAction<{
      mobilenumber?: string;
      otp?: string;
      name?: string;
      address?: string;
    }>
  >;
  isVerified: boolean;
  sendOtp: () => void;
  isLoading: boolean;
  verifyOtp: () => void;
  isLoadingVerification: boolean;
  otpDisable: boolean;
  setOtpDisable: React.Dispatch<React.SetStateAction<boolean>>;
  resendOtp: () => void;
  isResendLoading: boolean;
  create_transaction: () => void;
  transactionLoading: boolean;
  isModalVisible: boolean;
  handleOk: () => void;
}

interface VerificationMobileProps {
  onbackClick: () => void;
  verificationformdata: VerificationFormData;
  setverificationformdata: React.Dispatch<
    React.SetStateAction<VerificationFormData>
  >;
}

const VerificationFormMobile: React.FC<VerificationMobileProps> = ({
  onbackClick,
  verificationformdata,
  setverificationformdata,
  selectedRole,
  isVerified,
  sendOtp,
  isLoading,
  verifyOtp,
  isLoadingVerification,
  otpDisable,
  setOtpDisable,
  resendOtp,
  isResendLoading,
  create_transaction,
  transactionLoading,
  isModalVisible,
  handleOk,
}) => {
  const navigate = useNavigate();
  const [otpshow, setotpshow] = useState<{ otp: boolean; detail: boolean }>({
    otp: false,
    detail: false,
  });

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return (
    <div>
      <Card
        style={{
          borderRadius: "18px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      >
        {Object.keys(user).length === 0 && (
          <>
            <h2
              style={{
                textAlign: "center",
                marginBottom: "40px",
                fontWeight: "700",
                fontFamily: "Inter",
              }}
            >
              Verification
            </h2>
            <h4
              style={{
                marginBottom: "3px",
                fontSize: "12px",
                fontWeight: "700",
                fontFamily: "Inter",
              }}
            >
              Mobile Number
            </h4>

            <Space
              style={{
                height: "35px",
                width: "100%",
                borderRadius: "8px",
                border: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                padding: "0",
                justifyContent: "space-between",
              }}
            >
              <CustomInputMobile
                placeholder="Enter your mobile number"
                type="number"
                value={verificationformdata?.mobilenumber || ""}
                onChange={(e: string) => {
                  setverificationformdata({
                    ...verificationformdata,
                    mobilenumber: e,
                  });
                  setOtpDisable(false);
                }}
                style={{
                  flex: 1,
                  border: "none",
                  borderBottomRightRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
              />
              <CustomButtonMobile
                loading={isLoading}
                disabled={otpDisable}
                // disabled={!!verificationformdata?.otp}
                label="Send OTP"
                onClick={() => {
                  sendOtp();
                  setotpshow({
                    ...otpshow,
                    otp: true,
                  });
                }}
                style={{
                  marginTop: 0,
                  height: 35,
                  border: "none",
                  borderRadius: "5px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  fontSize: "12px",
                }}
              />
            </Space>

            {verificationformdata?.mobilenumber && otpDisable && (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <div>
                  <h4
                    style={{
                      marginBottom: "3px",
                      fontSize: "12px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                    }}
                  >
                    Enter OTP
                  </h4>
                  <OTPInput
                    value={verificationformdata?.otp || ""}
                    onChange={(otp: string) => {
                      setverificationformdata({ ...verificationformdata, otp });
                    }}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    containerStyle={{ marginLeft: -5 }}
                    renderInput={(props) => (
                      <input
                        {...props}
                        type="number"
                        maxLength={1}
                        style={{
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                          margin: "0 5px 0 5px",
                          fontSize: "12px",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          backgroundColor: props.value ? "#ECEEEA" : "white",
                        }}
                        onKeyDown={(e) => {
                          if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                  {isLoadingVerification && <p>Loading ...</p>}
                  <div
                    style={{
                      fontSize: "12px",
                      marginTop: "10px",
                      fontWeight: "400",
                      fontFamily: "Inter",
                    }}
                  >
                    Didn’t receive the code?{" "}
                    <a
                      onClick={() => {
                        resendOtp();
                      }}
                      style={{
                        color: "#000",
                        textDecorationLine: "underline",
                        fontWeight: "600",
                        fontFamily: "Inter",
                      }}
                    >
                      {isResendLoading ? "Loading ..." : "Resend OTP"}
                    </a>
                  </div>
                </div>
              </motion.div>
            )}
          </>
        )}

        {(isVerified || Object.keys(user).length !== 0) && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div>
              <h4
                style={{
                  marginBottom: "3px",
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "Inter",
                }}
              >
                Name
              </h4>
              <CustomInputMobile
                placeholder="Your Name"
                value={verificationformdata?.name || ""}
                onChange={(e: string) => {
                  setverificationformdata({
                    ...verificationformdata,
                    name: e,
                  });
                }}
              />
              <h4
                style={{
                  marginBottom: "3px",
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "Inter",
                }}
              >
                Pickup Address
              </h4>
              <MultilinesInputMobile
                placeholder="Your Address"
                value={verificationformdata?.address || ""}
                onChange={(e: string) => {
                  setverificationformdata({
                    ...verificationformdata,
                    address: e,
                  });
                }}
              />
            </div>
          </motion.div>
        )}

        <Space
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CustomButtonMobile
            fill="outline"
            label="Previous"
            onClick={onbackClick}
            style={{
              fontSize: "12px",
              width: "150px",
            }}
          />
          <CustomButtonMobile
            loading={transactionLoading}
            disabled={
              !verificationformdata?.name || !verificationformdata?.address
            }
            label="Create Transaction"
            onClick={() => {
              create_transaction();
            }}
            style={{
              fontSize: "12px",
              width: "150px",
            }}
          />
        </Space>
      </Card>

      <Modal
        visible={isModalVisible}
        closeOnMaskClick
        onClose={() => {
          handleOk();
        }}
        content={
          <div style={{ textAlign: "center", padding: "0px 20px" }}>
            <img
              src={success}
              alt="success"
              style={{ width: "40px", height: "40px", marginRight: 7 }}
            />
            <h2
              style={{
                fontSize: "16px",
                fontWeight: "700",
                fontFamily: "Inter",
                marginTop: 0,
              }}
            >
              Successful!
            </h2>
            <p
              style={{
                fontSize: "14px",
                marginTop: 0,
                fontFamily: "Inter",
                fontWeight: "400",
              }}
            >
              You have successfully joined the transaction.
            </p>
          </div>
        }
      />
    </div>
  );
};

export default VerificationFormMobile;
